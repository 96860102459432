import { FunctionComponent } from "react";
import forside from "./bilder/forside.png";

export const StartSide: FunctionComponent = () => {
  return (
    <div className="App App-header">
      <div style={{ margin: "10px" }}>
        <img src={forside} width="500" alt="Forside" />
      </div>
      <p>Her kommer hjemmesiden til Sameiet Knarvik Terrasse.</p>
    </div>
  );
};
